









































































import {apiAdminChangeStatus, apiAdminEdit, apiAdminList} from '@/api/setting/permissions'
import {Component, Vue, Watch} from 'vue-property-decorator'
import {RequestPaging} from "@/utils/util";

@Component({
  components: {}
})
export default class Auth extends Vue {
  pager: RequestPaging = new RequestPaging()
  form = {}

  search() {
    this.pager.page = 1
    this.getAdminList()
  }

  getAdminList() {
    this.pager
      .request({
        callback: apiAdminList,
        params: this.form
      })
      .catch(() => {

      })
  }

  async changeKefuStatus(value: 0 | 1, data: any) {
    await apiAdminChangeStatus({
      id: data.id,
      kefu_status: data.kefu_status,
    })
      .finally(() => {
        this.getAdminList()
      })
  }

  created() {
    this.getAdminList()
  }
}
