import request from "@/plugins/axios";
import * as Interface from "@/api/setting/permissions.d";

/** S 管理员 **/
// 管理员列表
export const apiAdminList = (
    params: Interface.AdminList_Req
): Promise<Interface.AdminList_Res> =>
    request.get("/auth.admin/lists", { params });

export const apiAdminOtherList = (params: any) =>
    request.get("/auth.admin/Otherlists", { params });

// 管理员详情
export const apiAdminDetail = (
    params: Interface.AdminDetail_Req
): Promise<Interface.AdminDetail_Res> =>
    request.get("/auth.admin/detail", { params });

// 添加管理员
export const apiAdminAdd = (data: Interface.AdminAdd_Req): Promise<any> =>
    request.post("/auth.admin/add", data);

export const apiAdminOtheradd = (data: any): Promise<any> =>
    request.post("/auth.admin/Otheradd", data);

export const apiAdminOtheredit = (data: any): Promise<any> =>
    request.post("/auth.admin/Otheredit", data);
export const apiAdminOtherdelete = (data: any): Promise<any> =>
    request.post("/auth.admin/Otherdelete", data);
// 删除管理员
export const apiAdminDelete = (data: Interface.AdminDelete_Req): Promise<any> =>
    request.post("/auth.admin/delete", data);

// 编辑管理员
export const apiAdminEdit = (data: Interface.AdminEdit_Req): Promise<any> =>
    request.post("/auth.admin/edit", data);

export const apiAdminChangeStatus = (data: any): Promise<any> =>
    request.post("/auth.admin/changeStatus", data);

/** E 管理员 **/

/** S 角色 **/
// 角色列表
export const apiRoleList = (
    params?: Interface.RoleList_Req
): Promise<Interface.RoleList_Res> =>
    request.get("/auth.role/lists", { params });

// 角色详情
export const apiRoleDetail = (
    params: Interface.RoleDetail_Req
): Promise<Interface.RoleDetail_Res> =>
    request.get("/auth.role/detail", { params });

// 添加角色
export const apiRoleAdd = (data: Interface.RoleAdd_Req): Promise<any> =>
    request.post("/auth.role/add", data);

// 编辑角色
export const apiRoleEdit = (data: Interface.RoleEdit_Req): Promise<any> =>
    request.post("/auth.role/edit", data);

// 删除角色
export const apiRoleDelete = (data: Interface.RoleDelete_Req): Promise<any> =>
    request.post("/auth.role/delete", data);

// 权限菜单
export const apiAuthMenu = () => request.post("/config/getMenu");

// 供应商权限
export const apiSupplierlists = (params: any) =>
    request.get("/auth.role/Supplierlists", { params });

export const apiSupplierMenu = () => request.get("/config/getSupplierMenu");
export const apiSupplierAddRoleAdd = (data: any) =>
    request.post("/auth.role/SupplierAdd", data);
export const apiSupplierAddRoleEdit = (data: any) =>
    request.post("/auth.role/SupplierEdit", data);
export const apiSupplierDelete = (data: any) =>
    request.post("/auth.role/SupplierDelete", data);
// 商城权限
export const apiShoplists = (params: any) =>
    request.get("/auth.role/Shoplists", { params });
export const apiShopMenu = () => request.get("/config/getShopMenu");
export const apiShopAddRoleAdd = (data: any) =>
    request.post("/auth.role/ShopAdd", data);
export const apiShopAddRoleEdit = (data: any) =>
    request.post("/auth.role/ShopEdit", data);
export const apiShopDelete = (data: any) =>
    request.post("/auth.role/ShopDelete", data);

/** E 角色 **/
